import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import { Navbar } from "./navigation/navbar/Navbar";
import ScrollToTop from "./components/rest/ScrollToTop";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Router />
      </BrowserRouter>
    </div>
  );
}

export default App;
