export const links = [
  {
    path: "/",
    name: "main",
  },
  {
    path: "/about",
    name: "about",
  },
  {
    path: "/contacts",
    name: "contacts",
  },
  // {
  //   path: "/news",
  //   label: "News",
  //   name: "news",
  // },
  // {
  //   path: "/careers",
  //   label: "Careers",
  //   name: "careers",
  // },
];
